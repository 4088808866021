import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import './element/index.css'
import "@/css/font.css";

import * as filters from '@/util/filters' // 引入工具类

Object.keys(filters).forEach(key => {
  // 注册过滤器
  Vue.filter(key, filters[key])
})


import filterItem from '@/components/filterItem.vue'
//注册为全局组件
Vue.component('filterItem', filterItem)
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
