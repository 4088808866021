<template>
  <div class="filterItem">
    <div class="head-box">
      <div class="filter-box">
        <el-input
          v-model="keyword"
          @keyup.enter.native="getNewList()"
          placeholder="请输入内容"
        ></el-input>
        <el-select
          v-model="deptId"
          placeholder="请选择部门"
          multiple
          collapse-tags
          @change="getNewList()"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="(item, index) in deptList"
            :key="index"
          ></el-option>
        </el-select>
        <el-select
          v-model="custId"
          placeholder="请选择客户"
          multiple
          collapse-tags
          @change="getNewList()"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="(item, index) in userItem"
            :key="index"
          ></el-option>
        </el-select>
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          @change="getNewList()"
        >
        </el-date-picker>
        <el-button type="primary" :loading="btnLoading" @click="getNewList()">
          搜索
        </el-button>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { deptMyList, custMyList } from "@/api/request/common/index";
export default {
  name: "filterItem",
  data() {
    return {
      btnLoading: false,
      keyword: "",
      deptList: [],
      userItem: [],
      deptId: [],
      time: [],
      custId: [],
    };
  },
  watch: {
    "$parent.btnLoading"() {
      this.btnLoading = this.$parent.btnLoading;
    },
  },
  components: {},
  created() {
    // console.log("//////////");
    this.detpartList();
    this.userList();
  },
  methods: {
    detpartList() {
      deptMyList()
        .then((res) => {
          // console.log(res);
          this.deptList = res.data.data;
        })
        .catch(() => {});
    },
    userList() {
      custMyList()
        .then((res) => {
          // console.log(res);
          this.userItem = res.data.data;
        })
        .catch(() => {});
    },
    getNewList() {
      this.$parent.keyword = this.keyword;
      //   console.log(this.time);
      this.$parent.searchData = {
        startTime: this.time[0] || "",
        endTime: this.time[1] || "",
        deptId: this.deptId.join(","),
        custId: this.custId.join(","),
      };
      //   console.log(this.$parent.searchData);
      this.$parent.getNewList();
    },
    addFrom() {
      this.$parent.addFrom();
    },
  },
};
</script>
<style scoped lang='scss'>
.filterItem {
  .head-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .filter-box {
    display: flex;
    .el-select {
      width: 180px;
      margin-right: 22px;
    }
    .el-date-editor {
      margin-right: 22px;
    }
    .el-input {
      width: 200px;
      margin-right: 18px;
    }
  }
}
</style>