
import request from '@/api/axios';

// 部门列表
export const deptMyList = (query) => {
    return request({
        url: '/dept/myList',
        method: 'get',
        params: query
    })
}

// 新增修改部门列表
export const editDept = (query) => {
    return request({
        url: '/dept',
        method: 'post',
        data: query
    })
}

// 删除部门列表
export const delDept = (query) => {
    return request({
        url: '/dept',
        method: 'delete',
        data: query
    })
}

// 客户列表
export const custMyList = (query) => {
    return request({
        url: '/cust/myList',
        method: 'get',
        params: query
    })
}

// 新增修改客户列表
export const editCustMyList = (query) => {
    return request({
        url: '/cust',
        method: 'post',
        data: query
    })
}

// 删除客户列表
export const delMyList = (query) => {
    return request({
        url: '/cust',
        method: 'delete',
        data: query
    })
}

// 用户列表
export const custUser = (query) => {
    return request({
        url: '/user/myList',
        method: 'get',
        params: query
    })
}

// 新增修改用户
export const editUser = (query) => {
    return request({
        url: '/user',
        method: 'post',
        data: query
    })
}

// 删除客户列表
export const delUser = (query) => {
    return request({
        url: '/user',
        method: 'delete',
        data: query
    })
}