
import Vue from "vue";
import VueRouter from "vue-router";
import { getStore } from "@/util/store";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
    redirect: '/workbench',
    children: [
      {
        path: "/workbench",
        name: "workbench",
        meta: {
          url: "/workbench",
          title: "工作台"
        },
        component: () => import("@/views/workbench/index.vue"),
      },
      {
        path: "/order",
        name: "order",
        meta: {
          url: "/order",
          title: "门店"
        },
        component: () => import("@/views/order/index.vue"),
        redirect: '/order/userPrintItem',
        children: [
          {
            path: "/order/adminPrintItem",
            name: "adminPrintItem",
            meta: {
              url: "/order",
              title: "管理员打印列表",
              path: "/order/adminPrintItem",
            },
            component: () => import("@/views/order/adminPrintItem/index.vue"),
          },
        ]
      },
      {
        path: "/dueIncome",
        name: "dueIncome",
        meta: {
          url: "/dueIncome",
          title: "应收管理"
        },
        component: () => import("@/views/dueIncome/index.vue"),
        redirect: '/dueIncome/summarizing',
        children: [
          {
            path: "/dueIncome/summarizing",
            name: "dueIncome/summarizing",
            meta: {
              url: "/dueIncome",
              title: "应收汇总",
              path: "/dueIncome/summarizing",
            },
            component: () => import("@/views/dueIncome/summarizing/index.vue"),
          },
          {
            path: "/dueIncome/detail",
            name: "dueIncome/detail",
            meta: {
              url: "/dueIncome",
              title: "应收明细",
              path: "/dueIncome/detail",
            },
            component: () => import("@/views/dueIncome/detail/index.vue"),
          },
        ]
      },
      {
        path: "/makeOutAnInvoice",
        name: "makeOutAnInvoice",
        meta: {
          url: "/makeOutAnInvoice",
          title: "开票管理"
        },
        component: () => import("@/views/makeOutAnInvoice/index.vue"),
        redirect: '/makeOutAnInvoice/summarizing',
        children: [
          {
            path: "/makeOutAnInvoice/summarizing",
            name: "makeOutAnInvoice/summarizing",
            meta: {
              url: "/makeOutAnInvoice",
              title: "开票汇总",
              path: "/makeOutAnInvoice/summarizing",
            },
            component: () => import("@/views/makeOutAnInvoice/summarizing/index.vue"),
          },
          {
            path: "/makeOutAnInvoice/detail",
            name: "makeOutAnInvoice/detail",
            meta: {
              url: "/makeOutAnInvoice",
              title: "开票明细",
              path: "/makeOutAnInvoice/detail",
            },
            component: () => import("@/views/makeOutAnInvoice/detail/index.vue"),
          },
        ]
      },
      {
        path: "/returnedMoney",
        name: "returnedMoney",
        meta: {
          url: "/returnedMoney",
          title: "回款管理"
        },
        component: () => import("@/views/returnedMoney/index.vue"),
        redirect: '/returnedMoney/summarizing',
        children: [
          {
            path: "/returnedMoney/summarizing",
            name: "returnedMoney/summarizing",
            meta: {
              url: "/returnedMoney",
              title: "回款汇总",
              path: "/returnedMoney/summarizing",
            },
            component: () => import("@/views/returnedMoney/summarizing/index.vue"),
          },
          {
            path: "/returnedMoney/detail",
            name: "returnedMoney/detail",
            meta: {
              url: "/returnedMoney",
              title: "回款明细",
              path: "/returnedMoney/detail",
            },
            component: () => import("@/views/returnedMoney/detail/index.vue"),
          },
        ]
      },
      {
        path: "/userInfo",
        name: "userInfo",
        meta: {
          url: "/userInfo",
          title: "基础信息"
        },
        component: () => import("@/views/userInfo/index.vue"),
        redirect: '/userInfo/client',
        children: [
          {
            path: "/userInfo/client",
            name: "userInfo/client",
            meta: {
              url: "/userInfo",
              title: "客户管理",
              path: "/userInfo/client",
            },
            component: () => import("@/views/userInfo/client/index.vue"),
          },
          {
            path: "/userInfo/department",
            name: "userInfo/department",
            meta: {
              url: "/userInfo",
              title: "部门管理",
              path: "/userInfo/department",
            },
            component: () => import("@/views/userInfo/department/index.vue"),
          },
          {
            path: "/userInfo/user",
            name: "userInfo/user",
            meta: {
              url: "/userInfo",
              title: "用户管理",
              path: "/userInfo/user",
            },
            component: () => import("@/views/userInfo/user/index.vue"),
          },
        ]
      },

    ]
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/AboutView.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录"
    },
    component: () => import("@/views/login/index.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(to)
  if (to.meta.title) {
    document.title = `财务管理 | ${to.meta.title}`;
  } else {
    document.title = "财务管理";
  }

  // 判断路由跳转  如果用户两小时未操作  清楚缓存
  // const time = getStore({ name: "lastRequestTime" });
  // const newTime = parseInt(new Date().getTime() / 1000);
  // if (!time) {
  //   clearStore({ type: 1 });
  //   clearStore();
  // } else if (newTime - Number(time) > 7200) {
  //   clearStore();
  //   clearStore({ type: 1 });
  // }
  // 特殊判断不需要登录
  if (to.meta.routeWhitelist) {
    next();
    return;
  }
  const userInfo = getStore({ name: "userinfo" });

  if (to.name != "login") {
    if (userInfo && userInfo.token) {
      next();
    } else {
      next({ path: "/login" });
    }
  } else if (userInfo && userInfo.token) {
    next({ name: "home" });
  } else {
    next();
  }
});
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default router;
